import { Component, OnInit } from '@angular/core';
import { TalentService } from '../talent.service';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { WorkflowDetailsDialogComponent } from './workflow-details-dialog.component';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

interface User {
  id: number;
  name: string;
}

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {
  // Recruiters to filter - only these will be shown in the dropdown
  recruiterFilter: string[] = ['Ananta', 'Anita', 'Denise', 'Joanne', 'John', 'Siyuan'];

  // Recruiters
  recruiters: string[] = [];
  // Map of recruiter names to IDs
  recruiterIds: { [key: string]: string } = {};
  selectedRecruiter = 'All';  // Default to show all recruiters

  // Date range
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  // All workflow statuses (used for data processing)
  workflowStatuses = [
    'Prospect',
    'Urgent Prospect',
    'All Prospect',
    'Recruiter Screen',
    'Resume Submitted',
    'First Interview',
    'Selected',
    'Offer Accepted',
    'Started'
  ];

  // Statuses to display in the table (excludes Prospect and Urgent Prospect)
  displayStatuses = [
    'All Prospect',
    'Recruiter Screen',
    'Resume Submitted',
    'First Interview',
    'Selected',
    'Offer Accepted',
    'Started'
  ];

  // Data
  workflowData: any = {};
  isLoading = true;
  selectedWorkflows: any[] = [];
  selectedWorkflowStatus: string = '';
  selectedWorkflowRecruiter: string = '';
  isLoadingWorkflows = false;

  constructor(
    private talentService: TalentService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    // Load users from the database
    this.talentService.getUsers().subscribe(
      (usersData: any) => {
        console.log('Received users data:', usersData);
        this.processUsersData(usersData); // Process users
        this.setupInitialState(); // Setup dates and load initial data
      },
      error => {
        console.error('Error fetching users data:', error);
        this.recruiters = [];
        this.recruiterIds = {};
        this.isLoading = false; // Set loading false even on error
        this.setupInitialState(); // Setup dates and load initial data even on error
      }
    );
  }

  // Removed ngAfterViewInit

  processUsersData(usersData: any) {
    if (Array.isArray(usersData) && usersData.length > 0) {
      const users = usersData as User[];
      const filteredUsers = users.filter(user => this.recruiterFilter.includes(user.name));

      if (filteredUsers.length > 0) {
        console.log('Found matching users in API response:', filteredUsers);
        this.recruiters = filteredUsers.map(user => user.name);
        filteredUsers.forEach(user => {
          this.recruiterIds[user.name] = String(user.id);
        });
      } else {
        console.log('No matching users found in API response, using empty values');
        this.recruiters = [];
        this.recruiterIds = {};
      }
    } else {
      console.log('No users data received or empty array, using empty values');
      this.recruiters = [];
      this.recruiterIds = {};
    }
    console.log('Populated recruiters:', this.recruiters);
    console.log('Populated recruiterIds:', this.recruiterIds);
  }

  setupInitialState() {
     // Set default date range to last 30 days
     const today = new Date();
     const thirtyDaysAgo = new Date();
     thirtyDaysAgo.setDate(today.getDate() - 30);
     this.dateRange.setValue({
       start: thirtyDaysAgo,
       end: today
     });

     console.log('Initial selectedRecruiter:', this.selectedRecruiter);

     // Load data on initialization
     this.loadData();
  }

  loadData() {
    this.isLoading = true;

    const startDate = this.dateRange.value.start ?
      moment(this.dateRange.value.start).format('YYYY-MM-DD') :
      moment().subtract(30, 'days').format('YYYY-MM-DD');

    const endDate = this.dateRange.value.end ?
      moment(this.dateRange.value.end).format('YYYY-MM-DD') :
      moment().format('YYYY-MM-DD');

    console.log('Formatted date range:', { startDate, endDate });

    let params: any = {
      start_date: startDate,
      end_date: endDate
    };

    if (this.selectedRecruiter !== 'All') {
      params.recruiter = this.recruiterIds[this.selectedRecruiter];
      console.log(`Selected recruiter: ${this.selectedRecruiter}, ID: ${params.recruiter}`);
    } else {
      console.log('Fetching data for all recruiters');
    }

    console.log('Fetching analytics data with params:', params);

    this.talentService.getWorkflowAnalytics(params).subscribe(
      data => {
        console.log('Received analytics data:', data);
        
        
        if (!data || (Array.isArray(data) && data.length === 0)) {
          console.log('No data received for the selected date range');
          this.processWorkflowData([]); // Process empty data to reset counts
        } else {
          this.processWorkflowData(data);
        }
        this.isLoading = false; // Mark loading as complete
      },
      error => {
        console.error('Error fetching analytics data:', error);
        this.processWorkflowData([]); // Process empty data to reset counts
        this.isLoading = false; // Mark loading as complete even on error
      }
    );
  }

  processWorkflowData(data: any) {
    // Reset workflowData for all known recruiters
    this.workflowData = {};
    if (this.recruiters.length > 0) {
      this.recruiters.forEach(recruiter => {
        this.workflowData[recruiter] = {};
        this.workflowStatuses.forEach(status => {
          this.workflowData[recruiter][status] = 0; // Initialize all statuses to 0
        });
      });
    } else {
      // Handle case with no recruiters (e.g., for 'No Data' label)
      this.workflowData['No Recruiter Data'] = {};
       this.workflowStatuses.forEach(status => {
          this.workflowData['No Recruiter Data'][status] = 0;
       });
    }

    // Create a reverse mapping from ID to recruiter name
    const idToRecruiter: { [key: string]: string } = {};
    for (const [name, id] of Object.entries(this.recruiterIds)) {
      idToRecruiter[id] = name;
    }

    // Process the received data
    if (Array.isArray(data)) {
      for (const item of data) {
        const userId = (item as any).user_id ? String((item as any).user_id) : null;
        const recruiter = userId ? idToRecruiter[userId] : null;
        let status = (item as any).status;

        // Only process if we have a valid recruiter and the recruiter exists in our data
        if (recruiter && this.workflowData[recruiter]) {
          // Check if the status is in our workflowStatuses array
          if (this.workflowStatuses.includes(status)) {
            this.workflowData[recruiter][status] = (this.workflowData[recruiter][status] || 0) + 1;
          }
        }
      }
    } else {
      console.error('Expected array of workflow data but received:', data);
    }
    // For "All Prospect", we need to count unique workflow IDs that have either "Prospect" or "Urgent Prospect" status
    // This ensures we don't double-count workflows that have both statuses
    if (Array.isArray(data)) {
      // Group workflows by recruiter and workflow ID
      const workflowsByRecruiter: { [recruiter: string]: { [wfId: string]: string[] } } = {};
      
      for (const item of data) {
        const userId = (item as any).user_id ? String((item as any).user_id) : null;
        const recruiter = userId ? idToRecruiter[userId] : null;
        const status = (item as any).status;
        const wfId = (item as any).wf_id;
        
        // Only process if we have a valid recruiter, workflow ID, and the status is either "Prospect" or "Urgent Prospect"
        if (recruiter && wfId && (status === 'Prospect' || status === 'Urgent Prospect')) {
          // Initialize the recruiter's workflow map if it doesn't exist
          if (!workflowsByRecruiter[recruiter]) {
            workflowsByRecruiter[recruiter] = {};
          }
          
          // Initialize the workflow's status array if it doesn't exist
          if (!workflowsByRecruiter[recruiter][wfId]) {
            workflowsByRecruiter[recruiter][wfId] = [];
          }
          
          // Add the status to the workflow's status array if it's not already there
          if (!workflowsByRecruiter[recruiter][wfId].includes(status)) {
            workflowsByRecruiter[recruiter][wfId].push(status);
          }
        }
      }
      
      // Count unique workflow IDs for each recruiter
      if (this.recruiters.length > 0) {
        this.recruiters.forEach(recruiter => {
          if (this.workflowData[recruiter]) {
            // Count the number of unique workflow IDs for this recruiter
            const uniqueWorkflowIds = workflowsByRecruiter[recruiter] ? Object.keys(workflowsByRecruiter[recruiter]).length : 0;
            this.workflowData[recruiter]['All Prospect'] = uniqueWorkflowIds;
          }
        });
      } else {
        // Handle case with no recruiters
        if (this.workflowData['No Recruiter Data']) {
          const noRecruiterData = workflowsByRecruiter['No Recruiter Data'] || {};
          this.workflowData['No Recruiter Data']['All Prospect'] = Object.keys(noRecruiterData).length;
        }
      }
    }
    
    console.log('Processed workflow data:', JSON.parse(JSON.stringify(this.workflowData)));
  }


  onRecruiterChange() {
    console.log('Recruiter changed to:', this.selectedRecruiter);
    // No need to do anything else, the table will update automatically
  }

  onDateChange() {
    console.log('Date range changed');
    // Reload data
    this.loadData();
  }

  downloadExcel() {
    console.log('Downloading Excel file');
    
    // Create data for Excel export
    const excelData: any[][] = [];
    
    // Add header row
    const headerRow: (string | number)[] = ['Recruiter', ...this.displayStatuses];
    excelData.push(headerRow);
    
    // Add data rows
    if (this.selectedRecruiter !== 'All') {
      // Single recruiter selected
      const dataRow: (string | number)[] = [
        this.selectedRecruiter,
        ...this.displayStatuses.map(status => 
          this.workflowData[this.selectedRecruiter] ? 
          this.workflowData[this.selectedRecruiter][status] : 0
        )
      ];
      excelData.push(dataRow);
    } else {
      // All recruiters
      this.recruiters.forEach(recruiter => {
        const dataRow: (string | number)[] = [
          recruiter,
          ...this.displayStatuses.map(status => 
            this.workflowData[recruiter] ? 
            this.workflowData[recruiter][status] : 0
          )
        ];
        excelData.push(dataRow);
      });
    }
    
    // Create worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelData);
    
    // Set column widths
    const colWidths: { wch: number }[] = [
      { wch: 15 }, // Recruiter column
      ...this.displayStatuses.map(() => ({ wch: 15 })) // Status columns
    ];
    ws['!cols'] = colWidths;
    
    // Create workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Recruiter Analytics');
    
    // Generate Excel file
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    
    // Save file
    const startDate = this.dateRange.value.start ? 
      moment(this.dateRange.value.start).format('YYYY-MM-DD') : 
      moment().subtract(30, 'days').format('YYYY-MM-DD');
    
    const endDate = this.dateRange.value.end ? 
      moment(this.dateRange.value.end).format('YYYY-MM-DD') : 
      moment().format('YYYY-MM-DD');
    
    const fileName = `Recruiter_Analytics_${startDate}_to_${endDate}.xlsx`;
    const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, fileName);
  }

  showWorkflowDetails(recruiter: string, status: string) {
    // Only proceed if there are workflows to show
    if (!this.workflowData[recruiter] || this.workflowData[recruiter][status] <= 0) {
      return;
    }

    this.selectedWorkflowRecruiter = recruiter;
    this.selectedWorkflowStatus = status;
    this.isLoadingWorkflows = true;
    this.selectedWorkflows = [];

    // Get the user ID for the selected recruiter
    const userId = this.recruiterIds[recruiter];
    
    // Get the same date range parameters used for the main analytics query
    const startDate = this.dateRange.value.start ?
      moment(this.dateRange.value.start).format('YYYY-MM-DD') :
      moment().subtract(30, 'days').format('YYYY-MM-DD');

    const endDate = this.dateRange.value.end ?
      moment(this.dateRange.value.end).format('YYYY-MM-DD') :
      moment().format('YYYY-MM-DD');

    // Create parameters for the state workflow details query
    let params: any = {
      start_date: startDate,
      end_date: endDate,
      status: status === 'All Prospect' ? 'All Prospect' : status
    };

    if (recruiter !== 'All') {
      params.recruiter_id = userId;
    }

    console.log('Fetching state workflow details with params:', params);

    // Use the new getStateWorkflowDetails method to get detailed workflow information
    this.talentService.getStateWorkflowDetails(params).subscribe(
      (data: any[]) => {
        console.log('Received state workflow details:', data);
        
        if (!data || data.length === 0) {
          console.log('No workflow details found');
          this.selectedWorkflows = [];
        } else {
          this.selectedWorkflows = data;
        }
        
        this.isLoadingWorkflows = false;
        this.openWorkflowDetailsDialog();
      },
      error => {
        console.error('Error fetching state workflow details:', error);
        this.isLoadingWorkflows = false;
        
        // Fallback to the old method if the new one fails
        this.fallbackToOldMethod(recruiter, status, userId, startDate, endDate);
      }
    );
  }

  // Fallback method to use the old approach if the new one fails
  fallbackToOldMethod(recruiter: string, status: string, userId: string, startDate: string, endDate: string) {
    console.log('Falling back to old method for fetching workflow details');
    
    let params: any = {
      start_date: startDate,
      end_date: endDate
    };

    if (recruiter !== 'All') {
      params.recruiter = userId;
    }

    // Fetch the analytics data to get the wf_ids
    this.talentService.getWorkflowAnalytics(params).subscribe(
      (data: any[]) => {
        console.log('Analytics data for workflow details:', data);
        
        // Filter the data to get only the workflows with the selected status and recruiter
        const filteredData = data.filter(item => {
          if (status === 'All Prospect') {
            return (item.status === 'Prospect' || item.status === 'Urgent Prospect') && 
                  (recruiter === 'All' || item.user_id === userId);
          } else {
            return item.status === status && 
                  (recruiter === 'All' || item.user_id === userId);
          }
        });
        
        console.log('Filtered data for workflow details:', filteredData);

        // Make sure we have valid workflow IDs
        const wfIds = [...new Set(filteredData.map(item => item.wf_id))].filter(id => id !== undefined && id !== null);
        console.log('Workflow IDs to fetch:', wfIds);
        
        if (wfIds.length === 0) {
          console.error('No valid workflow IDs found');
          this.selectedWorkflows = [];
          this.isLoadingWorkflows = false;
          this.openWorkflowDetailsDialog();
          return;
        }
        
        // Fetch details for each workflow
        const workflowPromises = wfIds.map(wfId => {
          console.log(`Fetching details for workflow ID: ${wfId}`);
          // Ensure wfId is a number
          return this.talentService.getWorkflowById(Number(wfId)).toPromise();
        });

        Promise.all(workflowPromises)
          .then(workflows => {
            console.log('Fetched workflow details:', workflows);
            
            // Flatten the array of arrays without using flat() for compatibility
            const flattenedWorkflows = [].concat(...workflows);
            console.log('Flattened workflows:', flattenedWorkflows);
            
            // Filter out any empty or invalid workflows
            this.selectedWorkflows = flattenedWorkflows.filter(wf => wf && Object.keys(wf).length > 0);
            console.log('Final selected workflows:', this.selectedWorkflows);
            this.isLoadingWorkflows = false;
            
            // Open dialog to display the workflows
            this.openWorkflowDetailsDialog();
          })
          .catch(error => {
            console.error('Error fetching workflow details:', error);
            this.isLoadingWorkflows = false;
          });
      },
      error => {
        console.error('Error fetching analytics data for workflows:', error);
        this.isLoadingWorkflows = false;
      }
    );
  }

  openWorkflowDetailsDialog() {
    const dialogRef = this.dialog.open(WorkflowDetailsDialogComponent, {
      width: '800px',
      data: {
        workflows: this.selectedWorkflows,
        status: this.selectedWorkflowStatus,
        recruiter: this.selectedWorkflowRecruiter
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed');
    });
  }
}
