import {
  Inject,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ViewChild,
  Optional,
} from "@angular/core";
import * as confetti from 'canvas-confetti';
import { ActivatedRoute } from "@angular/router";
import { Location, Time } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { Observable, Subscription } from "rxjs";

import { TalentService } from "../talent.service";
import { OpenRolesComponent } from "../open-roles/open-roles.component";
import { ShortlistComponent } from "../shortlist/shortlist.component";
import { WorkflowHistoryComponent } from "../workflow-history/workflow-history.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import { StarRatingComponent } from "../star-rating/star-rating.component";
import { FormGroup, FormControl } from "@angular/forms";
import { NotesHistoryComponent } from "../noteshistory/noteshistory.component";
import { convertUpdateArguments } from "@angular/compiler/src/compiler_util/expression_converter";
// import { DateAndTimeComponent } from '../dateandtime/dateandtime.component';
import { CandidateCommunicationHistoryComponent } from "../candidate-communication-history/candidate-communication-history.component";
import { WorkflowAiSummaryComponent } from "../workflow-ai-summary/workflow-ai-summary.component";

export interface DialogData {
  date: Date;
  time: Time;
}
@Component({
  selector: "candidate-detail",
  templateUrl: "./candidate-detail.component.html",
  styleUrls: ["./candidate-detail.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class CandidateDetailComponent implements OnInit {
  @Input() id;
  @Input("matTooltipClass")
  tooltipClass: string | string[] | Set<string> | { [key: string]: any };

  candidate: any;
  hasCandidate: Boolean = true;
  wf: any = { latest_status: "", notes: "", role_id: "", client: "" };
  fileUploadFormData: FormData = new FormData();
  regions: Array<any>;
  clients: Object[];
  skills: any[];
  resumeSources: any = [];
  vendorSource: any = [];
  businessKnowledge: any = [];
  workflowStatus: any = [];
  workingStatus: any = [];
  jobPreference: any = [];
  titles: any = [];
  date: Date;
  minDate: Date;
  dateStr: String;
  time: Time;
  hours;
  minutes;
  am_pm;
  label;
  dialogType;

  pendingClient;
  pendingSEQ;
  ps_reason: string;
  ps_selection;
  ps_ihi_client;
  ps_ihi_seq;
  ps_pi_client;
  ps_pi_seq;
  soc_connection;
  candidateWorkflows: any = [];
  candidateWorkflowsStatus: any = [];
  candidateContracts: any = [];
  workExperienceOptions: any = [];
  leavePeriod: any = [];
  wf_id;
  istrue = true;
  modified_by: any = [];
  add_alert_msg: String = "";
  last_viewed: any = "";
  graduationYearOptions = [];
  flags: any[];
  other_files;
  otherFileUploadFormData: FormData;
  notes_archive: any = [];
  list_regions: any = [];

  hasChanged: Boolean = false;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private talentService: TalentService,
    private location: Location,
    @Optional() @Inject(MAT_DIALOG_DATA) data: any
  ) {}

  updateRating(event) {
    console.log("received event:" + event);
    this.candidate.rating = event + 1;
  }

  ngOnInit() {
    // this.soc_connection = this.talentService.simpleObservable.subscribe(resp=>{});
    this.getCandidateDetail();
    this.getLastViewed();
    this.istrue = true;
    // this.getRegions();
    // this.getClients();
    // this.getSkills();
    for (let i = 0; i < 30; i++) {
      this.workExperienceOptions[i] = String(i + 1);
    }
    this.leavePeriod[0] = "Immediate";
    for (let i = 1; i < 13; i++) {
      this.leavePeriod[i] = i + " Week";
    }
    this.jobPreference = this.talentService.jobPreference;
    this.titles = this.talentService.titles;
    this.workingStatus = this.talentService.workingStatus;

    this.talentService.getSkills().subscribe((skills) => {
      this.skills = skills;
    });
    this.talentService.getRegions().subscribe((regions) => {
      this.regions = regions;
      this.generate_list_region();
    });
    this.talentService.getClients().subscribe((clients) => {
      this.clients = clients;
    });
    this.talentService.getResumeSource().subscribe((resumeSources) => {
      this.resumeSources = resumeSources;
    });
    this.talentService.getBusinessKnowledge().subscribe((businessKnowledge) => {
      this.businessKnowledge = businessKnowledge;
    });
    this.talentService.getVendorSource().subscribe((vendorSource) => {
      this.vendorSource = vendorSource;
    });

    this.talentService.getWorkflowStatus().subscribe((workflowStatus) => {
      this.workflowStatus = workflowStatus;
    });
    this.talentService
      .getCandidateWorkflow(this.id)
      .subscribe((candidateWorkflows) => {
        this.candidateWorkflows = candidateWorkflows;
        this.getWorkflowsStatus();
      });
    this.talentService
      .getCandidateFlags()
      .subscribe((flags) => (this.flags = flags));

    this.talentService
      .getCandidateContractRate(this.id)
      .subscribe((contract) => {
        this.candidateContracts = contract;
      });
    this.graduationYearOptions = Array(100)
      .fill(new Date().getFullYear()+4)
      .map((x, y) => x - y);

    this.talentService.getNotesHistory(this.id).subscribe((result) => {
      this.notes_archive = result;
      for (var i = 0; i < this.notes_archive.length; i++) {
        this.date = this.notes_archive[i].update_time;
        this.dateStr = String(this.date);
        let update_time = this.dateStr.replace("T", " ");
        this.notes_archive[i].update_time = update_time;
      }
    });
  }

  generate_list_region() {
    let list_res = {};
    for (let r of this.regions) {
      if (r.country in list_res) {
        list_res[r.country].push(r.state_name);
      } else {
        list_res[r.country] = [r.state_name];
      }
    }
    this.list_regions = list_res;
  }

  getLastViewed() {
    this.talentService
      .getCandidateLastViewedTime(this.id)
      .subscribe((result) => {
        this.last_viewed = result;
      });
  }

  getWorkflowsStatus() {
    for (let i = 0; i < this.candidateWorkflows.length; i++) {
      const element = this.candidateWorkflows[i];

      this.talentService
        .getCandidateWorkflowStates(element.id)
        .subscribe((status) => {
          this.candidateWorkflowsStatus.push(status);
        });
    }
  }

  getCandidateDetail(): void {
    const id = +this.route.snapshot.paramMap.get("id");
    if (id) {
      this.id = id;
    }
    this.talentService.getCandidateDetail(this.id).subscribe((candidate) => {
      this.candidate = candidate;
      if (candidate) {
        this.candidate.notes = "";
      } else {
        this.hasCandidate = false;
      }
    });
  }
  goBack(): void {
    this.location.back();
  }

  hasDone = false;
  isLoading = false;

  save(): void {
    this.hasDone = false;
    this.isLoading = true;
    this.talentService.saveCandidateDetail(this.candidate).subscribe(
      (res) => {
        this.sendMessage();
        this.getCandidateDetail();
      },
      (error) => {
        window.alert("Error saving candidate");
      },
      () => {
        this.isLoading = false;
      }
    );

    if (this.resumes.length !== 0) {
      let fileUploadFormData = new FormData();
      for (const file of this.resumes) {
        fileUploadFormData.append("file", file);
      }
      fileUploadFormData.append("candidate_id", this.candidate.id);
      this.talentService.fileUpload(fileUploadFormData).subscribe(
        (res) => {
          this.candidate.resumes.unshift({
            filename: res.filename,
            file_id: res.file_id,
          });
          this.resumes = [];
        },
        (error) => {
          window.alert("Error saving Resume");
        },
        () => {
          this.resumes = [];
          this.getCandidateDetail();
          this.isLoading = false;
        }
      );
    }
    if (this.otherFiles.length !== 0) {
      let otherFileUploadFormData = new FormData();
      for (const file of this.otherFiles) {
        otherFileUploadFormData.append("file", file);
      }
      otherFileUploadFormData.append("candidate_id", this.candidate.id);
      this.talentService.otherFileUpload(otherFileUploadFormData).subscribe(
        (res) => {
          this.candidate.other_files.unshift({
            filename: res.filename,
            file_id: res.file_id,
          });
          this.otherFiles = [];
        },
        (error) => {
          window.alert("Error saving files");
        },
        () => {
          this.otherFiles = [];
          this.getCandidateDetail();
          this.isLoading = false;
        }
      );
    }
    this.hasDone = true;
    this.isLoading = false;
  }

  addAlert(): void {
    if (this.candidate.alert_date != null && this.candidate.alert_date != "") {
      this.talentService
        .addAlert(this.candidate.id, this.candidate.alert_date)
        .subscribe(
          () => this.sendMessage(),
          (error) => {
            window.alert("Error adding alert");
          },
          () => {
            this.add_alert_msg = "Alert added.";
          }
        );
    }
  }

  filterWorkStatusByCountry(country) {
    if (this.workingStatus) {
      return this.workingStatus.filter((x) => x.country === country);
    } else {
      return this.workingStatus;
    }
  }

  filterStatesByCountry(country) {
    if (this.regions) {
      return this.regions.filter((x) => x.country === country);
    } else {
      return this.regions;
    }
  }

  triggerConfetti(): void {
    // Use setTimeout to delay the confetti slightly, allowing the dialog to appear first
    setTimeout(() => {
      const canvas = document.createElement('canvas');
      canvas.style.position = 'fixed';
      canvas.style.top = '0';
      canvas.style.left = '0';
      canvas.style.width = '100vw';
      canvas.style.height = '100vh';
      canvas.style.zIndex = '10000'; // Much higher z-index to ensure it's on top of dialogs
      canvas.style.pointerEvents = 'none';
      document.body.appendChild(canvas);
      
      const myConfetti = confetti.create(canvas, {
        resize: true,
        useWorker: true
      });
      
      myConfetti({
        particleCount: 200,
        spread: 160,
        origin: { y: 0.6 }
      });
      
      // Remove canvas after animation completes
      setTimeout(() => {
        document.body.removeChild(canvas);
      }, 5000);
    }, 100); // Small delay to ensure dialog is rendered first
  }

  optionchange(workflow): void {
    var label = workflow.latest_status;
    var id = workflow.id;
    console.log(label);
    
    // Trigger confetti when status contains the word "Interview"
    if (label.includes("Interview")) {
      // For any interview status, we'll trigger the confetti after the dialog appears
      setTimeout(() => {
        this.triggerConfetti();
      }, 300); // Delay to ensure dialog is fully rendered
    }
    if (
      label == "Phone Interview" ||
      label == "In-house Interview" ||
      label == "Start Date" ||
      label == "Pending Scheduling" ||
      label === "Closed" ||
      label == "First Interview" ||
      label == "Second Interview" ||
      label == "Additional Interview" ||
      label == "Recruiter Screen"
    ) {
      this.label = label;
      this.wf_id = id;
      console.log(label);
      var dHeight = "400px";
      var dWidth = "700px";
      var interviewType = "";
      var title = "";
      if (
        label == "Phone Interview" ||
        label == "In-house Interview" ||
        label == "First Interview" ||
        label == "Second Interview" ||
        label == "Additional Interview"
      ) {
        this.dialogType = "interviewTime";
        dHeight = "500px";
        dWidth = "800px";
        if (label === "Phone Interview") {
          interviewType = "phone";
          title =
            workflow.client +
            " Phone Interview - " +
            this.candidate.first_name +
            " " +
            this.candidate.last_name;
        } else if (label === "First Interview") {
          interviewType = "inhouse";
          title =
            workflow.client +
            " - First Interview - " +
            this.candidate.first_name +
            " " +
            this.candidate.last_name;
        } else if (label === "Second Interview") {
          interviewType = "inhouse";
          title =
            workflow.client +
            " - Second Interview - " +
            this.candidate.first_name +
            " " +
            this.candidate.last_name;
        } else if (label === "Additional Interview") {
          interviewType = "inhouse";
          title =
            workflow.client +
            " - Additional Interview - " +
            this.candidate.first_name +
            " " +
            this.candidate.last_name;
        } else {
          interviewType = "inhouse";
          title =
            workflow.client +
            " - On-Site Interview - " +
            this.candidate.first_name +
            " " +
            this.candidate.last_name;
        }
      } else if (label == "Recruiter Screen") {
        this.dialogType = "recruiter";
        dHeight = "500px";
        dWidth = "800px";
        interviewType = "inhouse";
        title =
          this.candidate.first_name +
          " " +
          this.candidate.last_name +
          " - Phone Number: " +
          this.candidate.phone_mobile;
      } else if (label == "Pending Scheduling") {
        this.dialogType = "pendingScheduling";
      } else if (label === "Closed") {
        this.dialogType = "closed";
      } else {
        this.dialogType = "startDate";
        title =
          this.candidate.first_name +
          " " +
          this.candidate.last_name +
          " starts at " +
          workflow.client;
      }
      let dialogRef = this.dialog.open(DateAndTimeComponent, {
        height: dHeight,
        width: dWidth,
        data: {
          date: this.date,
          hours: this.hours,
          minutes: this.minutes,
          am_pm: this.am_pm,
          dialogType: this.dialogType,
          interviewType: interviewType,
          title: title,
          email: this.candidate.email_address,
          resumes: this.candidate.resumes,
          other_files: this.candidate.other_files,
        },
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.date = result.date;
          this.dateStr = String(result.date);
          this.dateStr = this.dateStr.slice(0, -41);
          this.hours = result.hours;
          this.minutes = result.minutes;
          this.am_pm = result.am_pm;
          this.dialogType = result.dialogType;
          this.ps_reason = result.ps_reason;
          this.ps_selection = result.ps_selection;
          this.ps_ihi_client = result.ps_ihi_client;
          this.ps_ihi_seq = result.ps_ihi_seq;
          this.ps_pi_client = result.ps_pi_client;
          this.ps_pi_seq = result.ps_pi_seq;
          this.pendingClient = result.pendingClient;
          this.pendingSEQ = result.pendingSEQ;
          console.log(this.ps_reason);
          console.log(this.ps_selection);
          var add_to_notes;
          for (var i = 0; i < this.candidateWorkflows.length; i++) {
            if (this.wf_id == this.candidateWorkflows[i].id) {
              if (this.label == "Phone Interview") {
                if (this.pendingSEQ) {
                  add_to_notes = " - SEQ";
                } else if (this.pendingClient) {
                  add_to_notes = " - Client";
                }
              } else if (this.label == "In-house Interview") {
                if (this.pendingSEQ) {
                  add_to_notes = " - SEQ";
                } else if (this.pendingClient) {
                  add_to_notes = " - Client";
                }
              }
              if (
                this.minutes &&
                (label == "Phone Interview" ||
                  label == "In-house Interview" ||
                  label == "Start Date")
              ) {
                if (this.pendingSEQ || this.pendingClient) {
                  this.candidateWorkflows[i].notes =
                    this.dateStr +
                    ", " +
                    this.hours +
                    ":" +
                    this.minutes +
                    "" +
                    this.am_pm +
                    "  " +
                    "(EST)" +
                    add_to_notes;
                } else {
                  this.candidateWorkflows[i].notes =
                    this.dateStr +
                    ", " +
                    this.hours +
                    ":" +
                    this.minutes +
                    "" +
                    this.am_pm +
                    "  " +
                    "(EST)";
                }
              } else if (
                !this.hours &&
                !this.minutes &&
                !this.am_pm &&
                label == "Start Date"
              ) {
                if (this.pendingSEQ || this.pendingClient) {
                  this.candidateWorkflows[i].notes =
                    this.dateStr + add_to_notes;
                } else {
                  this.candidateWorkflows[i].notes = this.dateStr;
                }
              } else if (!this.hours && !this.minutes && !this.am_pm) {
                this.hours = "00";
                this.minutes = "00";
                this.am_pm = "-";
                if (this.pendingSEQ || this.pendingClient) {
                  this.candidateWorkflows[i].notes =
                    this.dateStr +
                    ", " +
                    this.hours +
                    ":" +
                    this.minutes +
                    "" +
                    this.am_pm +
                    "  " +
                    "(EST)" +
                    add_to_notes;
                } else {
                  this.candidateWorkflows[i].notes =
                    this.dateStr +
                    ", " +
                    this.hours +
                    ":" +
                    this.minutes +
                    "" +
                    this.am_pm +
                    "  " +
                    "(EST)";
                }
              } else {
                if (!this.minutes) {
                  this.minutes = "00";
                }

                if (this.pendingClient) {
                  this.candidateWorkflows[i].notes =
                    this.dateStr +
                    ", " +
                    this.hours +
                    ":" +
                    this.minutes +
                    "" +
                    this.am_pm +
                    "  " +
                    "(EST)" +
                    " - Client";
                } else if (this.pendingSEQ) {
                  this.candidateWorkflows[i].notes =
                    this.dateStr +
                    ", " +
                    this.hours +
                    ":" +
                    this.minutes +
                    "" +
                    this.am_pm +
                    "  " +
                    "(EST)" +
                    " - SEQ";
                } else {
                  this.candidateWorkflows[i].notes =
                    this.dateStr +
                    ", " +
                    this.hours +
                    ":" +
                    this.minutes +
                    "" +
                    this.am_pm +
                    "  " +
                    "(EST)";
                }
              }
              if (this.ps_reason || this.ps_selection) {
                this.candidateWorkflows[i].notes = this.ps_reason;
              }
              // if (
              //   this.ps_ihi_client == true ||
              //   this.ps_ihi_seq == true ||
              //   this.ps_pi_client == true ||
              //   this.ps_pi_seq == true
              // ) {
              //   if (this.ps_ihi_client == true) {
              //     this.candidateWorkflows[i].notes =
              //       "Pending Scheduling - Client Inhouse Interview";
              //   }
              //   if (this.ps_ihi_seq == true) {
              //     this.candidateWorkflows[i].notes =
              //       "Pending Scheduling - SEQ Inhouse Interview";
              //   }
              //   if (this.ps_pi_client == true) {
              //     this.candidateWorkflows[i].notes =
              //       "Pending Scheduling - Client Phone Interview";
              //   }
              //   if (this.ps_pi_seq == true) {
              //     this.candidateWorkflows[i].notes =
              //       "Pending Scheduling - SEQ Phone Interview";
              //   }
              // }
              if (label === "Closed") {
                this.candidateWorkflows[i].notes = result.reasons
                  .map((n) => n.name)
                  .join(", ");
                this.talentService
                  .saveWorkflowClosed(
                    this.candidateWorkflows[i],
                    result.reasons.map((n) => n.id)
                  )
                  .subscribe();
              } else {
                this.talentService
                  .saveWorkflow(this.candidateWorkflows[i])
                  .subscribe();
              }
            }
            console.log(this.candidateWorkflows[i].notes);
          }
          if (!result.dontShowShortlist) {
            let dialogRef = this.dialog.open(ShortlistComponent, {
              height: "600px",
              width: "800px",
              data: {
                shortlistContext: "addToShortlist",
                shortlistContextId: this.id,
              },
            });
          }
        }
      });
    } else {
      for (var i = 0; i < this.candidateWorkflows.length; i++) {
        if (id === this.candidateWorkflows[i].id) {
          this.candidateWorkflows[i].notes = label + " - ";
        }
      }
    }
  }

  doneMessage1 = "";
  notificationCount;
  saveWorkflows(): void {
    this.doneMessage1 = "";
    for (let i = 0; i < this.candidateWorkflows.length; i++) {
      const element = this.candidateWorkflows[i];
      if (i == this.candidateWorkflows.length - 1) {
        this.talentService.saveWorkflow(element).subscribe(
          (status) => {
            console.log(status);
          },
          (err) => {
            console.log(err);
          },
          () => {
            this.doneMessage1 = "Done";
            this.talentService
              .getCandidateWorkflow(this.id)
              .subscribe((candidateWorkflows) => {
                this.candidateWorkflows = candidateWorkflows;
                this.getWorkflowsStatus();
                this.sendMessageWorkflow();
              });
          }
        );
      } else {
        this.talentService.saveWorkflow(element).subscribe(
          (status) => {
            console.log(status);
          },
          () => {
            this.sendMessageWorkflow();
          }
        );
      }
    }
    this.talentService.getNotificationCount().subscribe((notificationCount) => {
      this.notificationCount = notificationCount;
      this.talentService.sendNotificationCount(this.notificationCount);
    });
    // this.talentService.updateSocket().subscribe(()=>{});
  }

  ngOnDestroy() {
    // this.soc_connection.unsubscribe()
  }

  addToRole(): void {
    let dialogRef = this.dialog.open(OpenRolesComponent, {
      height: "600px",
      width: "1300px",
      data: { openRolesContext: "addToRole", openRolesContextId: this.id },
    });
    console.log("adding to role");
  }

  addToShortlist(): void {
    let dialogRef = this.dialog.open(ShortlistComponent, {
      height: "600px",
      width: "800px",
      data: { shortlistContext: "addToShortlist", shortlistContextId: this.id },
    });
    console.log("adding to shortlist");
  }

  workflowHistory(wf_id): void {
    let dialogRef = this.dialog.open(WorkflowHistoryComponent, {
      height: "600px",
      width: "800px",
      data: { wf_id: wf_id },
    });
  }

  workflowSummary(wf_id): void {
    let dialogRef = this.dialog.open(WorkflowAiSummaryComponent, {
      height: "600px",
      width: "800px",
      data: { wf_id: wf_id },
    });
  }

  notesHistory(candidate_id, first_name, last_name) {
    let dialogRef = this.dialog.open(NotesHistoryComponent, {
      height: "600px",
      width: "800px",
      data: {
        candidate_id: candidate_id,
        first_name: first_name,
        last_name: last_name,
      },
    });
  }

  communicationHistory(candidate_id) {
    let dialogRef = this.dialog.open(CandidateCommunicationHistoryComponent, {
      height: "600px",
      width: "800px",
      data: { candidate_id: candidate_id ,is_client_contact:0},
    });
  }

  addWorkflow(): void {
    this.wf.candidate_id = this.id;
    this.talentService.addWorkflow(this.wf).subscribe(
      (status) => {
        console.log(status);
      },
      (err) => {
        console.log(err);
      },
      () => {
        this.wf = { latest_status: "", notes: "", role_id: "", client: "" };
        this.talentService
          .getCandidateWorkflow(this.id)
          .subscribe((candidateWorkflows) => {
            this.candidateWorkflows = candidateWorkflows;
            console.log(this.candidateWorkflows);
            this.getWorkflowsStatus();
          });
        console.log("Completed");
      }
    );
    console.log(this.wf);
  }

  getRegions(): void {
    this.talentService
      .getRegions()
      .subscribe((regions) => (this.regions = regions));
  }
  getClients(): void {
    this.talentService
      .getClients()
      .subscribe((clients) => (this.clients = clients));
  }
  getSkills(): void {
    this.talentService
      .getSkills()
      .subscribe((skills) => (this.skills = skills));
  }

  @Output() messageEvent = new EventEmitter<Object>();

  sendMessage() {
    var x = Object(this.candidate);
    for (var i = 0; i < this.skills.length; i++) {
      if (this.skills[i].id == this.candidate.primary_skill) {
        x.primary_skill_string = this.skills[i].skill;
      }
    }
    this.messageEvent.emit(x);
  }

  @Output() messageEventWorkflow = new EventEmitter<Object>();

  sendMessageWorkflow() {
    this.messageEventWorkflow.emit(this.candidateWorkflows);
  }

  Allfiles = [];
  resumes = [];
  otherFiles = [];
  // fileUpload(id,event)  {
  //   let fileList: FileList= event.target.files;
  //   if(fileList.length > 0) {
  //     for (var i=0;i<fileList.length;i++){
  //       let file: File = fileList[i];
  //       this.fileUploadFormData.append('file', file);
  //       this.fileUploadFormData.append('candidate_id',id);
  //       this.fileUploadFormData.append('candidate_id',id);
  //       this.Allfiles.push(file["name"])
  //     }
  //   }
  // }
  fileUpload(event) {
    let resumesList = event.target.files;
    for (const item of resumesList) {
      this.resumes.push(item);
    }
  }

  fileDrop(event) {
    for (const item of event) {
      this.resumes.push(item);
    }
  }

  removeResume(i) {
    this.resumes.splice(i, 1);
  }

  otherFileUpload(event) {
    let otherFileList = event.target.files;
    for (const item of otherFileList) {
      this.otherFiles.push(item);
    }
  }

  otherFileDrop(event) {
    for (const item of event) {
      this.otherFiles.push(item);
    }
  }

  removeOtherFile(i) {
    this.otherFiles.splice(i, 1);
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  formatTime(lastModified) {
    let modified_date = new Date(lastModified);
    var curr_date = modified_date.getDate();
    var curr_month = modified_date.getMonth() + 1; //Months are zero based
    var curr_year = modified_date.getFullYear();
    return curr_year + "-" + curr_month + "-" + curr_date;
  }
  // otherFileUpload(id,event)  {
  //   this.uploaded = false;
  //   this.other_files=[];
  //   let fileList: FileList= event.target.files;
  //   console.log(fileList);
  //   if(fileList.length > 0) {
  //     let formData:FormData = new FormData();
  //     for (var i=0;i<fileList.length;i++){
  //       let file: File = fileList[i];
  //       console.log(file);
  //       formData.append('file', file);
  //       formData.append('candidate_id',id);
  //       this.other_files[i]=fileList[i].name;
  //     }
  //       this.otherFileUploadFormData = formData;
  //   }
  // }

  toggler(key, value) {
    if (this.candidate[key] === true) {
      this.candidate[key] = "1";
    } else {
      this.candidate[key] = "0";
    }
  }

  updateHasChanged() {
    this.hasChanged = true;
  }

  handleSkillUpdate(e){

    if (e=='text' && this.candidate.newSkill && this.candidate.newSkill.length>0 &&this.candidate.primary_skill) {
      this.candidate.primary_skill = null;
    }
    else if(e=='select' && this.candidate.primary_skill){
      this.candidate.newSkill =null
    };
    this.updateHasChanged() ;
  }
  setCurrentResume(e) {
    this.talentService.updateResumeDetails(e).subscribe(candidate => {
      this.candidate = candidate
    })

  }
}

@Component({
  selector: "dateandtime",
  templateUrl: "dateandtime.component.html",
  styleUrls: ["./dateandtime.component.css"],
})
export class DateAndTimeComponent {
  dialogType;
  eventTitle;
  constructor(
    public dialogRef: MatDialogRef<DateAndTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private talentService: TalentService
  ) {}

  date: Date;
  time: Time;
  hours: any = [];
  minutes: any = [];
  options_list: any[];
  attachments: any = [];
  hours1;
  minutes1;
  am_pm;
  hours2;
  minutes2;
  am_pm2;
  minDate;
  ps_reason: string = "";
  ps_selection: number;
  ps_ihi_seq;
  ps_ihi_client;
  ps_pi_seq;
  ps_pi_client;
  resumes;
  other_files;
  pendingClient: boolean;
  pendingSEQ: boolean;
  message;
  interviewType;
  notifications = [{ method: "popup", minutes: 30, time: 1 }];
  location = "";
  description = "";
  guest_list = [];
  recruiter_list = [];
  sourcer_list = [];
  closedReasons: any = [];
  closedChecked: any = {};
  pickerData: any = [];

  ngOnInit() {
    this.dialogType = this.data["dialogType"];
    this.interviewType = this.data["interviewType"];
    this.eventTitle = this.data["title"];
    this.guest_list = [{ email: this.data["email"].trim() }];
    this.resumes = this.data["resumes"];
    this.other_files = this.data["other_files"];
    this.minDate = new Date();
    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        this.hours = this.hours.concat([String("0" + i)]);
      } else {
        this.hours = this.hours.concat([String(i)]);
      }
    }
    if (this.dialogType === "closed") {
      this.talentService
        .getClosedReasons()
        .subscribe((options) => (this.closedReasons = options));
    }

    this.talentService.getYodaweUsers().subscribe((user) => {
      this.options_list = user;
    });
  }
  saveData() {
    if (this.dialogType != "pendingScheduling") {
      if (
        this.dialogType === "interviewTime" ||
        this.dialogType == "recruiter"
      ) {
        if (
          this.date &&
          this.hours1 &&
          this.minutes1 &&
          this.am_pm &&
          this.hours2 &&
          this.minutes2 &&
          this.am_pm2
        ) {
          let startTime = this.convertDateFormat(
            this.date,
            this.hours1,
            this.minutes1,
            this.am_pm
          );
          let endTime = this.convertDateFormat(
            this.date,
            this.hours2,
            this.minutes2,
            this.am_pm2
          );

          if (new Date(startTime) <= new Date(endTime)) {
            if (this.interviewType === "phone") {
              this.location = "Phone";
            }
            if (this.interviewType === "online") {
              this.location = "Online";
            }
            var event = {
              summary: this.eventTitle,
              location: this.location,
              description: this.description,
              start: {
                dateTime: startTime,
                timeZone: "America/New_York",
              },
              end: {
                dateTime: endTime,
                timeZone: "America/New_York",
              },
              attendees: this.guest_list.concat(
                this.recruiter_list.concat(this.sourcer_list)
              ),
              reminders: {
                useDefault: false,
                overrides: this.notifications.map((n) => {
                  return { method: n.method, minutes: n.minutes * n.time };
                }),
              },
              attachments: this.attachments,
            };

            if (this.dialogType === "recruiter") {
              console.log(event);
              this.talentService.addRecruiterCalendarEvent(event).then(
                (response) => {
                  console.log("HERE");
                  this.dialogRef.close({
                    date: this.date,
                    hours: this.hours1,
                    minutes: this.minutes1,
                    am_pm: this.am_pm,
                    dialogType: this.dialogType,
                    pendingClient: this.pendingClient,
                    pendingSEQ: this.pendingSEQ,
                    ps_reason: this.ps_reason,
                    ps_selection: this.ps_selection,
                    ps_ihi_client: this.ps_ihi_client,
                    ps_ihi_seq: this.ps_ihi_seq,
                    ps_pi_client: this.ps_pi_client,
                    ps_pi_seq: this.ps_pi_seq,
                  });
                },
                (error) => {
                  console.log(error);
                  this.message = "Error: " + error;
                }
              );
            } else {
              this.talentService.addGoogleCalendarEvent(event).then(
                (response) => {
                  console.log("HERE");
                  this.dialogRef.close({
                    date: this.date,
                    hours: this.hours1,
                    minutes: this.minutes1,
                    am_pm: this.am_pm,
                    dialogType: this.dialogType,
                    pendingClient: this.pendingClient,
                    pendingSEQ: this.pendingSEQ,
                    ps_reason: this.ps_reason,
                    ps_selection: this.ps_selection,
                    ps_ihi_client: this.ps_ihi_client,
                    ps_ihi_seq: this.ps_ihi_seq,
                    ps_pi_client: this.ps_pi_client,
                    ps_pi_seq: this.ps_pi_seq,
                  });
                },
                (error) => {
                  console.log(error);
                  this.message = "Error: " + error;
                }
              );
            }
          } else {
            this.message = "Invalid Time range.";
          }
        } else {
          this.message = "Date and Time are required fields.";
        }
      } else if (this.dialogType === "closed") {
        let reasons = this.closedReasons.filter(
          (n) => this.closedChecked[n.id]
        );
        this.dialogRef.close({
          dialogType: this.dialogType,
          dontShowShortlist: true,
          reasons,
        });
      } else if (this.dialogType === "startDate") {
        if (this.date) {
          console.log("minutes", parseInt(this.minutes1) + 15);
          let startTime = this.convertDateFormat(
            this.date,
            this.hours1,
            this.minutes1,
            this.am_pm
          );
          let endTime = this.convertDateFormat(
            this.date,
            this.hours1,
            parseInt(this.minutes1) + 10,
            this.am_pm
          );
          console.log("start time", this.data);
          var candidateStartEvent = {
            summary: this.eventTitle,
            location: "",
            description: " ",
            start: {
              date: this.date.toISOString().slice(0, 10),
              timeZone: "America/New_York",
            },
            end: {
              date: this.date.toISOString().slice(0, 10),
              timeZone: "America/New_York",
            },
            attendees: " ",
            reminders: {
              useDefault: false,
              overrides: this.notifications.map((n) => {
                return { method: n.method, minutes: n.minutes * n.time };
              }),
            },
          };
          this.talentService
            .addStartDateCalendarEvent(candidateStartEvent)
            .then(
              (response) => {
                console.log("HERE");
                this.dialogRef.close({
                  date: this.date,
                  hours: this.hours1,
                  minutes: this.minutes1,
                  am_pm: this.am_pm,
                  dialogType: this.dialogType,
                  pendingClient: this.pendingClient,
                  pendingSEQ: this.pendingSEQ,
                  ps_ihi_client: this.ps_ihi_client,
                  ps_ihi_seq: this.ps_ihi_seq,
                  ps_pi_client: this.ps_pi_client,
                  ps_pi_seq: this.ps_pi_seq,
                });
              },
              (error) => {
                console.log(error);
                this.message = "Error: " + error;
              }
            );
          this.dialogRef.close({
            date: this.date,
            hours: this.hours1,
            minutes: this.minutes1,
            am_pm: this.am_pm,
            dialogType: this.dialogType,
            pendingClient: this.pendingClient,
            pendingSEQ: this.pendingSEQ,
            ps_reason: this.ps_reason,
            ps_selection: this.ps_selection,
            ps_ihi_client: this.ps_ihi_client,
            ps_ihi_seq: this.ps_ihi_seq,
            ps_pi_client: this.ps_pi_client,
            ps_pi_seq: this.ps_pi_seq,
          });
        } else {
          if (!this.date) {
            this.message = "Please add a date";
          }
          // if (!this.date && !this.hours1 && !this.minutes1 && !this.am_pm) {
          //   this.message = "Please add the date and time.";
          // } else if (!this.date) {
          //   this.message = "Please add a date";
          // }
          // else if (!this.hours1 || this.minutes1 || this.am_pm) {
          //   this.message = "Please add the specific time";
          // }
        }
      } else {
        if (this.date && this.hours1 && this.minutes1 && this.am_pm) {
          this.dialogRef.close({
            date: this.date,
            hours: this.hours1,
            minutes: this.minutes1,
            am_pm: this.am_pm,
            dialogType: this.dialogType,
            pendingClient: this.pendingClient,
            pendingSEQ: this.pendingSEQ,
            ps_reason: this.ps_reason,
            ps_selection: this.ps_selection,
            ps_ihi_client: this.ps_ihi_client,
            ps_ihi_seq: this.ps_ihi_seq,
            ps_pi_client: this.ps_pi_client,
            ps_pi_seq: this.ps_pi_seq,
          });
        } else {
          if (!this.date && !this.hours1 && !this.minutes1 && !this.am_pm) {
            this.message = "Please add the date and time.";
          } else if (!this.date) {
            this.message = "Please add a date";
          } else if (!this.hours1 || this.minutes1 || this.am_pm) {
            this.message = "Please add the specific time";
          }
        }
      }
    } else {
      if (this.ps_selection || this.ps_reason) {
        this.dialogRef.close({
          date: this.date,
          hours: this.hours1,
          minutes: this.minutes1,
          am_pm: this.am_pm,
          dialogType: this.dialogType,
          pendingClient: this.pendingClient,
          pendingSEQ: this.pendingSEQ,
          ps_reason: this.ps_reason,
          ps_selection: this.ps_selection,
          ps_ihi_client: this.ps_ihi_client,
          ps_ihi_seq: this.ps_ihi_seq,
          ps_pi_client: this.ps_pi_client,
          ps_pi_seq: this.ps_pi_seq,
        });
      } else {
        this.message = "Choose a field";
      }
    }
  }

  convertDateFormat(date, hours, minutes, am_pm) {
    var hourInt = parseInt(hours);
    if (hourInt === 12) {
      hourInt = 0;
    }
    if (am_pm === "PM") {
      hourInt += 12;
    }
    var hourString = hourInt.toString();
    if (hourInt < 10) {
      hourString = "0" + hourInt.toString();
    }
    return (
      this.date.toISOString().slice(0, 10) +
      "T" +
      hourString +
      ":" +
      minutes +
      ":00"
    );
  }
  penSch(selection) {
    this.ps_selection = selection;
    if (selection == 1) {
      this.ps_reason = "First Interview - Client";
    } else if (selection == 2) {
      this.ps_reason = "First Interview - SEQ";
    } else if (selection == 3) {
      this.ps_reason = "Second Interview - Client";
    } else if (selection == 4) {
      this.ps_reason = "Second Interview - SEQ";
    } else if (selection == 5) {
      this.ps_reason = "Additional Interview - Client";
    } else if (selection == 6) {
      this.ps_reason = "Additional Interview - SEQ";
    }
  }
  penSchIHICli() {
    if (this.ps_ihi_client == true) {
      this.ps_ihi_seq = false;
      this.ps_pi_client = false;
      this.ps_pi_seq = false;
    }
  }
  penSchIHISEQ() {
    if (this.ps_ihi_seq == true) {
      this.ps_ihi_client = false;
      this.ps_pi_client = false;
      this.ps_pi_seq = false;
    }
  }
  penSchPICli() {
    if (this.ps_pi_client == true) {
      this.ps_ihi_seq = false;
      this.ps_ihi_client = false;
      this.ps_pi_seq = false;
    }
  }
  penSchPISEQ() {
    if (this.ps_pi_seq == true) {
      this.ps_ihi_seq = false;
      this.ps_pi_client = false;
      this.ps_ihi_client = false;
    }
  }
  penSchCli() {
    if (this.pendingClient == true) {
      this.pendingSEQ = false;
    }
  }
  penSchSEQ() {
    if (this.pendingSEQ == true) {
      this.pendingClient = false;
    }
  }
  // handleAddressChange(address) {
  //   console.log(address);
  //   this.location = address;
  // }
  removeNotification(i) {
    this.notifications = this.notifications
      .slice(0, i)
      .concat(this.notifications.slice(i + 1));
  }
  addNotification() {
    this.notifications = this.notifications.concat([
      { method: "popup", minutes: 30, time: 1 },
    ]);
  }
  removeGuest(i) {
    this.guest_list = this.guest_list
      .slice(0, i)
      .concat(this.guest_list.slice(i + 1));
  }
  addGuest() {
    this.guest_list = this.guest_list.concat([{ email: "" }]);
  }
  removeRecruiter(i) {
    this.recruiter_list = this.recruiter_list
      .slice(0, i)
      .concat(this.recruiter_list.slice(i + 1));
  }
  addRecruiter() {
    this.recruiter_list = this.recruiter_list.concat([{ email: "" }]);
  }
  onChangeRecruiter(event, i) {
    this.recruiter_list[i].email = event;
  }
  removeSourcer(i) {
    this.sourcer_list = this.sourcer_list
      .slice(0, i)
      .concat(this.sourcer_list.slice(i + 1));
  }
  addSourcer() {
    this.sourcer_list = this.sourcer_list.concat([{ email: "" }]);
  }
  onChangeSourcer(event, i) {
    this.sourcer_list[i].email = event;
  }
  closedCheckboxChange(id) {
    this.closedChecked[id] = !this.closedChecked[id];
  }
  onClickAddAttachment() {
    this.pickerData = this.talentService.googleDrivePicker();

    console.log("data", this.data);
    this.pickerData.then((element) => {
      element.forEach((e, index) => {
        this.attachments.push({
          fileUrl: e["url"],
          title: e["name"],
          mimeType: e["mimeType"],
          iconLink: e["iconUrl"],
          fileId: e["id"],
        });
      });
    });
  }
  removeAttachment(i) {
    this.attachments = this.attachments
      .slice(0, i)
      .concat(this.attachments.slice(i + 1));
  }
}
